import React from 'react';
import {
  translatable,
  text,
  number,
  blockEditor,
  bool,
  reference,
  referenceMany,
  select,
  dateTime,
} from '../fields';
import { Block } from '../ContentEditor';
import { ContentTypes } from './types';
import idx from 'idx';
import { ContentDescription, Content } from '../types';

export const bodyEditorBlocks = ({ additionalBlocks = [] } = {}) => [
  {
    type: 'imageHeadingBlock',
    fields: [
      {
        name: 'image',
        title: 'Image',
        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
      },
      {
        name: 'maxWidth',
        title: 'Max Width (px or %)',
        type: text(),
      },
      {
        name: 'maxHeight',
        title: 'Max Height (px or %)',
        type: text(),
      },
      {
        name: 'margin',
        title: 'Margin (px or %)',
        type: text(),
      },
    ],
  },
  {
    type: 'gameProviderLogosBlock',
    fields: [
      {
        name: 'gameProviders',
        title: 'Game Providers',
        type: referenceMany({ contentTypes: [ContentTypes.GAME_PROVIDER] }),
      },
    ],
  },
  {
    type: 'loginFormBlock',
    fields: [
      {
        name: 'loginForm',
        title: 'Login Form',
        type: reference({ contentTypes: [ContentTypes.LOGIN_FORM] }),
      },
    ],
  },
  {
    type: 'phoneVerificationFormBlock',
    fields: [
      {
        name: 'phoneVerificationForm',
        title: 'Phone Verification Form',
        type: reference({ contentTypes: [ContentTypes.PHONE_VERIFICATION_FORM] }),
      },
    ],
  },
  {
    type: 'registerFormBlock',
    fields: [
      {
        name: 'onlySms',
        title: 'Only SMS',
        type: bool(),
      },
      {
        name: 'enableQuickDeposit',
        title: 'Enable Quick Deposit',
        type: bool(),
      },
    ],
  },
  {
    type: 'rewardsBlock',
    fields: [],
  },
  {
    type: 'challengesBlock',
    fields: [],
  },
  {
    type: 'gameSiteMapBlock',
    fields: [],
  },
  {
    type: 'additionalInformationBlock',
    fields: [
      {
        name: 'additionalInformationForm',
        title: 'Additional Information Form',
        type: reference({
          contentTypes: [ContentTypes.ADDITIONAL_INFORMATION_FORM],
        }),
      },
    ],
  },
  {
    type: 'updateEmailFormBlock',
    fields: [
      {
        name: 'updateEmailForm',
        title: 'Update Email Form Block',
        type: reference({ contentTypes: [ContentTypes.UPDDATE_EMAIL_FORM] }),
      },
    ],
  },
  {
    type: 'updatePersonalDetailsFormBlock',
    fields: [
      {
        name: 'updatePersonalDetailsForm',
        title: 'Update Personal Details Form Block',
        type: reference({
          contentTypes: [ContentTypes.UPDDATE_PERSONAL_DETAILS_FORM],
        }),
      },
    ],
  },
  {
    type: 'updateAddressFormBlock',
    fields: [
      {
        name: 'updateAddressForm',
        title: 'Update Address Form',
        type: reference({ contentTypes: [ContentTypes.UPDATE_ADDRESS_FORM] }),
      },
    ],
  },
  {
    type: 'updateMarketingSettingsFormBlock',
    fields: [
      {
        name: 'updateMarketingSettingsForm',
        title: 'Update Marketing Settings Form',
        type: reference({
          contentTypes: [ContentTypes.UPDATE_MARKETING_SETTINGS_FORM],
        }),
      },
    ],
  },
  {
    type: 'stepsBlock',
    fields: [
      {
        name: 'steps',
        title: 'Multi Step Progress',
        type: reference({ contentTypes: [ContentTypes.STEPS] }),
      },
      {
        name: 'activeIndex',
        title: 'Active Index (first index is 0)',
        type: number(),
      },
    ],
  },
  {
    type: 'gameListBlock',
    fields: [
      {
        name: 'gameList',
        title: 'Game List',
        type: reference({ contentTypes: [ContentTypes.GAME_LIST] }),
      },
    ],
  },
  {
    type: 'gameSearchBoxBlock',
    fields: [
      {
        name: 'gameSearchBox',
        title: 'Game Search Box',
        type: reference({ contentTypes: [ContentTypes.GAME_SEARCH_BOX] }),
      },
    ],
  },
  {
    type: 'gameStudioBlock',
    fields: [
      {
        name: 'gameStudio',
        title: 'Game Studio',
        type: reference({ contentTypes: [ContentTypes.GAME_STUDIO] }),
      },
    ],
  },
  {
    type: 'enterCodeFormBlock',
    fields: [
      {
        name: 'enterCodeForm',
        title: 'Enter Code Form',
        type: reference({ contentTypes: [ContentTypes.ENTER_LOGIN_FORM] }),
      },
    ],
  },
  {
    type: 'gameSearchTextFieldBlock',
    fields: [
      {
        name: 'gameSearchTextField',
        title: 'Game Search Text Field',
        type: reference({ contentTypes: [ContentTypes.GAME_SEARCH_TEXT_FIELD] }),
      },
    ],
  },
  {
    type: 'trustlyLoginBlock',
    fields: [],
  },
  {
    type: 'zimplerLoginBlock',
    fields: [],
  },
  {
    type: 'cashierBlock',
    fields: [
      {
        name: 'cashier',
        title: 'Cashier',
        type: reference({ contentTypes: [ContentTypes.CASHIER] }),
      },
    ],
  },
  {
    type: 'gameBlock',
    fields: [
      {
        name: 'game',
        title: 'Game',
        type: reference({ contentTypes: [ContentTypes.GAME] }),
      },
    ],
  },
  {
    type: 'menuBlock',
    fields: [
      {
        name: 'menu',
        title: 'Menu',
        type: reference({ contentTypes: [ContentTypes.MENU] }),
      },
    ],
  },
  {
    type: 'walletBlock',
    fields: [],
  },
  {
    type: 'sportsBookBlock',
    fields: [],
  },
  {
    type: 'paymentHistoryBlock',
    fields: [
      {
        name: 'showDeposits',
        title: 'Show Deposits',
        type: bool(),
      },
      {
        name: 'showWithdrawals',
        title: 'Show Withdrawals',
        type: bool(),
      },
    ],
  },
  {
    type: 'paymentMethodsBlock',
    fields: [
      {
        name: 'paymentMethods',
        title: 'Payment Methods',
        type: reference({ contentTypes: [ContentTypes.PAYMENT_METHODS] }),
      },
    ],
  },
  {
    type: 'gameRoundsBlock',
    fields: [],
  },
  {
    type: 'activitiesHistoryBlock',
    fields: [],
  },
  {
    type: 'sessionHistoryBlock',
    fields: [],
  },
  {
    type: 'rewardsHistoryBlock',
    fields: [],
  },
  {
    type: 'cashbackHistoryBlock',
    fields: [],
  },
  {
    type: 'cashbackOptInBlock',
    fields: [
      {
        name: 'cashbackOptIn',
        title: 'Cashback Opt In',
        type: reference({ contentTypes: [ContentTypes.CASHBACK_OPT_IN] }),
      },
    ],
  },
  {
    type: 'documentUploadBlock',
    fields: [
      {
        name: 'documentUploadMessage',
        title: 'Upload message:',
        type: text(),
      },
      {
        name: 'documentUploadSuccesMessage',
        title: 'Success message:',
        type: text(),
      },
      {
        name: 'documentUploadErrorMessage',
        title: 'Error message:',
        type: text(),
      },
    ],
  },
  {
    type: 'lockAccountFormBlock',
    fields: [
      {
        name: 'lockAccountForm',
        title: 'Self Exclusion Form',
        type: reference({ contentTypes: [ContentTypes.LOCK_ACCOUNT_FORM] }),
      },
    ],
  },
  {
    type: 'updateDepositLimitFormBlock',
    fields: [
      {
        name: 'updateDepositLimitForm',
        title: 'Update Deposit Limit Form',
        type: reference({ contentTypes: [ContentTypes.UPDATE_DEPOSIT_LIMIT_FORM] }),
      },
    ],
  },
  {
    type: 'updateWagerLimitFormBlock',
    fields: [
      {
        name: 'updateWagerLimitForm',
        title: 'Update Wager Limit Form',
        type: reference({ contentTypes: [ContentTypes.UPDATE_WAGER_LIMIT_FORM] }),
      },
    ],
  },
  {
    type: 'updateLossLimitFormBlock',
    fields: [
      {
        name: 'updateLossLimitForm',
        title: 'Update Loss Limit Form',
        type: reference({ contentTypes: [ContentTypes.UPDATE_LOSS_LIMIT_FORM] }),
      },
    ],
  },
  {
    type: 'updateSessionTimeLimitFormBlock',
    fields: [
      {
        name: 'updateSessionTimeLimitForm',
        title: 'Update Session Time Limit Form',
        type: reference({
          contentTypes: [ContentTypes.UPDATE_SESSION_TIME_LIMIT_FORM],
        }),
      },
    ],
  },
  {
    type: 'accessCashierBlock',
    fields: [
      {
        name: 'accessCashier',
        title: 'Access Cashier',
        type: reference({ contentTypes: [ContentTypes.ACCESS_CASHIER] }),
      },
    ],
  },
  {
    type: 'accordionBlock',
    fields: [
      {
        name: 'accordion',
        title: 'Accordion',
        type: reference({ contentTypes: [ContentTypes.ACCORDION] }),
      },
    ],
  },
  {
    type: 'cookieDeclarationBlock',
    fields: [],
  },
  {
    type: 'kycValidationBlock',
    fields: [
      {
        name: 'kycValidation',
        title: 'KYC Validation',
        type: reference({ contentTypes: [ContentTypes.KYC_VALIDATION] }),
      },
    ],
  },
  {
    type: 'kycVerificationBlock',
    fields: [
      {
        name: 'kycVerification',
        title: 'Kyc Verification Block',
        type: reference({ contentTypes: [ContentTypes.KYC_VERIFICATION] }),
      },
    ],
  },
  {
    type: 'seoBoxBlock',
    fields: [
      {
        name: 'seoBox',
        title: 'SEO Box',
        type: reference({ contentTypes: [ContentTypes.SEO_BOX] }),
      },
    ],
  },
  {
    type: 'promotionBlock',
    fields: [
      {
        name: 'promotion',
        title: 'Promotion',
        type: referenceMany({ contentTypes: [ContentTypes.PROMOTION] }),
      },
    ],
  },
  {
    type: 'affiliateBlock',
    fields: [
      {
        name: 'affiliate',
        title: 'Affiliate',
        type: referenceMany({ contentTypes: [ContentTypes.AFFILIATE] }),
      },
    ],
  },
  {
    type: 'guideBlock',
    fields: [
      {
        name: 'guide',
        title: 'Guide',
        type: referenceMany({ contentTypes: [ContentTypes.GUIDE] }),
      },
    ],
  },
  {
    type: 'newsBlock',
    fields: [
      {
        name: 'news',
        title: 'News',
        type: referenceMany({ contentTypes: [ContentTypes.NEWS] }),
      },
    ],
  },
  {
    type: 'customContentBoxBlock',
    fields: [
      {
        name: 'customContentBox',
        title: 'Custom Content Box',
        type: reference({ contentTypes: [ContentTypes.CUSTOM_CONTENT_BOX] }),
      },
    ],
  },
  {
    type: 'imageSliderBlock',
    fields: [
      {
        name: 'imageSlider',
        title: 'Image Slider',
        type: reference({ contentTypes: [ContentTypes.IMAGE_SLIDER] }),
      },
    ],
  },
  {
    type: 'simpleImageSliderBlock',
    fields: [
      {
        name: 'simpleImageSlider',
        title: 'Simple Image Slider',
        type: reference({ contentTypes: [ContentTypes.SIMPLE_IMAGE_SLIDER] }),
      },
    ],
  },
  {
    type: 'cardListBlock',
    fields: [
      {
        name: 'cardList',
        title: 'Card List',
        type: reference({ contentTypes: [ContentTypes.CARD_LIST] }),
      },
    ],
  },
  {
    type: 'cardBlock',
    fields: [
      {
        name: 'card',
        title: 'Card',
        type: reference({ contentTypes: [ContentTypes.CARD] }),
      },
    ],
  },
  {
    type: 'textSectionBlock',
    fields: [
      {
        name: 'textSection',
        title: 'Text Section',
        type: reference({ contentTypes: [ContentTypes.TEXT_SECTION] }),
      },
    ],
  },
  {
    type: 'ImageSectionBlock',
    fields: [
      {
        name: 'imageSection',
        title: 'Image Section',
        type: reference({ contentTypes: [ContentTypes.IMAGE_SECTION] }),
      },
    ],
  },
  {
    type: 'error404Block',
    fields: [
      {
        name: 'error404',
        title: 'Error 404',
        type: reference({ contentTypes: [ContentTypes.ERROR_404] }),
      },
    ],
  },
  {
    type: 'verifyEmailBlock',
    fields: [],
  },
  {
    type: 'pageLinkBlock',
    fields: [
      {
        name: 'page',
        title: 'Page',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
      {
        name: 'text',
        title: 'Text',
        type: text(),
      },
      {
        name: 'linkType',
        title: 'Link type',
        type: select({
          types: [
            { text: 'Button', value: 'button' },
            { text: 'Link', value: 'link' },
          ],
        }),
      },
      {
        name: 'linkType',
        title: 'Link type',
        type: select({
          types: [
            { text: 'Button', value: 'button' },
            { text: 'Link', value: 'link' },
          ],
        }),
      },
    ],
  },
  {
    type: 'termsAndConditionsBlock',
    fields: [
      {
        name: 'termsAndConditions',
        title: 'Terms and Conditions',
        type: reference({ contentTypes: [ContentTypes.TERMS_AND_CONDITIONS] }),
      },
    ],
  },

  {
    type: 'privacyPolicyBlock',
    fields: [
      {
        name: 'privacyPolicy',
        title: 'Privacy Policy',
        type: reference({ contentTypes: [ContentTypes.PRIVACY_POLICY] }),
      },
    ],
  },
  {
    type: 'formBlock',
    fields: [
      {
        name: 'form',
        title: 'Form',
        type: reference({ contentTypes: [ContentTypes.FORM] }),
      },
    ],
  },
  {
    type: 'wizardFormBlock',
    fields: [
      {
        name: 'forms',
        title: 'Forms',
        type: referenceMany({ contentTypes: [ContentTypes.FORM] }),
      },
    ],
  },
  {
    type: 'bannerBlock',
    fields: [
      {
        name: 'banner',
        title: 'Banner',
        type: reference({ contentTypes: [ContentTypes.BANNER] }),
      },
    ],
  },
  {
    type: 'gameStudioListBlock',
    fields: [
      {
        name: 'gameStudiosList',
        title: 'Studios List',
        type: referenceMany({ contentTypes: [ContentTypes.GAME_STUDIO] }),
      },
    ],
  },
  ...additionalBlocks,
];

export function createPage({
  locales,
  additionalBlocks = [],
  previewInStaging,
  previewInProduction,
}: {
  additionalBlocks?: Block[];
  locales: string[];
  previewInStaging?: (contentNode: Content) => void;
  previewInProduction?: (contentNode: Content) => void;
}): ContentDescription {
  return {
    contentType: ContentTypes.PAGE,
    title: ({ draft }: any) => draft?.path?.en,
    preview: ({ draft, locale }: any) => (
      <>{(draft?.path || {})[locale] || draft?.path?.en || draft?.identifier}</>
    ),
    previewInStaging,
    previewInProduction,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'menuTitle',
              title: 'Menu Title',
              type: text(),
            },
            {
              name: 'menuIcon',
              title: 'Menu Icon',
              type: text(),
            },
            {
              name: 'newText',
              title: 'New Text',
              type: text(),
            },
            {
              name: 'path',
              title: 'Path',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'keywords',
              title: 'Keywords',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: bodyEditorBlocks({ additionalBlocks }),
              }),
            },
          ],
          locales
        ),
      },
      {
        name: 'parentPage',
        title: 'Parent Page',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
      {
        name: 'robots',
        title: 'Robots',
        type: text(),
      },
      {
        name: 'identifier',
        title: 'Identifer',
        type: text(),
      },
      {
        name: 'changefreq',
        title: 'Change Frequency (Sitemap)',
        type: text(),
      },
      {
        name: 'priority',
        title: 'Priority (Sitemap)',
        type: text(),
      },
      {
        name: 'requiresAuthentication',
        title: 'Requires Authentication',
        type: bool(),
      },
      {
        name: 'hideLastPlayedGames',
        title: 'Hide Last Played Games',
        type: bool(),
      },
      {
        name: 'hideBreadcrumbs',
        title: 'Hide Breadcrumbs',
        type: bool(),
      },
      {
        name: 'backgroundImage',
        title: 'Has Background Image',
        type: bool(),
      },
      {
        name: 'redirectPath',
        title: 'Redirect to Internal Path (Only if required)',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
      {
        name: 'rewritePath',
        title: 'Rewite to External Path (Only if required)',
        type: text(),
      },
      {
        name: 'redirect',
        title: 'Redirect',
        type: select({
          types: [
            { text: 'Non', value: '' },
            { text: '301', value: '301' },
            { text: '302', value: '302' },
          ],
        }),
      },
    ],
  };
}

export function createSeoBox({
  locales,
  additionalBlocks = [],
}: {
  additionalBlocks?: Block[];
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.SEO_BOX,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: bodyEditorBlocks({ additionalBlocks }),
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCustomContentBox({
  locales,
  additionalBlocks = [],
}: {
  additionalBlocks?: Block[];
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.CUSTOM_CONTENT_BOX,
    preview: ({ draft }: any) => (draft && draft.title && draft.title.en ? draft.title.en : ''),
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'backgroundColor',
              title: 'Background Color (Ex: #242424)',
              type: text(),
            },
            {
              name: 'padding',
              title: 'Padding (Ex: 20px or 24px 14px)',
              type: text(),
            },
            {
              name: 'margin',
              title: 'Margin (Ex: 20px or 24px 14px)',
              type: text(),
            },
            {
              name: 'width',
              title: 'Width (Ex: 1024px or 60%)',
              type: text(),
            },
            {
              name: 'maxWidth',
              title: 'Max Width (Ex: 1024px or 60%)',
              type: text(),
            },
            {
              name: 'height',
              title: 'Height (Ex: 512px or 20%)',
              type: text(),
            },
            {
              name: 'maxHeight',
              title: 'Max Height (Ex: 512px or 20%)',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: bodyEditorBlocks({ additionalBlocks }),
              }),
            },
          ],
          locales
        ),
      },
      {
        name: 'identifier',
        title: 'Identifier',
        type: text(),
      },
    ],
  };
}

export function createLoginForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.LOGIN_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    title: ({ draft }: any) => idx(draft, (_) => _.title.en),
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'phoneNumberLabel',
              title: 'Phone Number Label',
              type: text(),
            },
            {
              name: 'sendCodeButton',
              title: 'Send Code Button',
              type: text(),
            },
            {
              name: 'secondOptionClickHere',
              title: 'Second Option Click Here Link',
              type: text(),
            },
            {
              name: 'secondOptionText',
              title: 'Second Option Click Here Text',
              type: text(),
            },
            {
              name: 'phoneNumberNotValidErrorMessage',
              title: 'Phone Number Not Valid Error Message',
              type: text(),
            },
            {
              name: 'disableInternalLogin',
              title: 'Disable Internal Login',
              type: bool(),
            },
            {
              name: 'enableZimplerLogin',
              title: 'Enable Zimpler Login',
              type: bool(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createPhoneVerificationForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.PHONE_VERIFICATION_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    title: ({ draft }: any) => idx(draft, (_) => _.title.en),
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'phoneNumberLabel',
              title: 'Phone Number Label',
              type: text(),
            },
            {
              name: 'sendCodeButton',
              title: 'Send Code Button',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCookieConsent({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.COOKIE_CONSENT,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'message',
              title: 'Message',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
            {
              name: 'acceptButton',
              title: 'Accept Button',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createSteps({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.STEPS,
    preview: ({ draft }: any) => idx(draft, (_) => _.title) || '',
    fields: [
      {
        name: 'title',
        title: 'Title',
        type: text(),
      },
      {
        type: translatable(
          [
            {
              name: 'step1',
              title: 'Step 1',
              type: text(),
            },
            {
              name: 'step2',
              title: 'Step 2',
              type: text(),
            },
            {
              name: 'step3',
              title: 'Step 3',
              type: text(),
            },
            {
              name: 'step4',
              title: 'Step 4',
              type: text(),
            },
            {
              name: 'step5',
              title: 'Step 5',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createEnterCodeForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.ENTER_LOGIN_FORM,
    preview: ({ draft }: any) => draft.title.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'enterCodeLabel',
              title:
                'Enter Code Label - Leave empty to set the placerholder with ENTER_CODE translation',
              type: text(),
            },
            {
              name: 'enterCodeButton',
              title: 'Enter Code Button',
              type: text(),
            },
            {
              name: 'resendText',
              title: 'Resend Text',
              type: text(),
            },
            {
              name: 'resendPathText',
              title: 'Resend Path Text (Deprecated)',
              type: text(),
            },
            {
              name: 'resendCodeLinkText',
              title: 'Resend code link Text',
              type: text(),
            },
            {
              name: 'resendTextPhoneVerification',
              title: 'Resend Text For Phone Verification',
              type: text(),
            },
            {
              name: 'resendPathTextPhoneVerification',
              title: 'Resend Path Text for Phone Verification (Deprecated)',
              type: text(),
            },
            {
              name: 'resendCodeLinkTextPhoneVerification',
              title: 'Resend code link Text for Phone Verification',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createGameList({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_LIST,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'slug',
              title: 'Slug',
              type: text(),
            },
            {
              name: 'enabled',
              title: 'Enabled',
              type: bool(),
            },
            {
              name: 'games',
              title: 'Games',
              type: referenceMany({ contentTypes: [ContentTypes.GAME] }),
            },
          ],
          locales
        ),
      },
      {
        name: 'filterByCategories',
        title: 'Categories Filter',
        type: referenceMany({ contentTypes: [ContentTypes.GAME_CATEGORY] }),
      },
      {
        name: 'filterByTags',
        title: 'Tags Filter',
        type: referenceMany({ contentTypes: [ContentTypes.TAG] }),
      },
      {
        name: 'filterByStudio',
        title: 'Studio Filter',
        type: referenceMany({ contentTypes: [ContentTypes.GAME_PROVIDER] }),
      },
      {
        name: 'recentlyPlayed',
        title: 'Show Recently Played',
        type: bool(),
      },
      {
        name: 'page',
        title: 'Linked Game List Page',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
      {
        name: 'default',
        title: 'Is Default',
        type: bool(),
      },
    ],
  };
}

export function createGameSearchBox({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_SEARCH_BOX,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'gameList',
              title: 'Game List',
              type: reference({ contentTypes: [ContentTypes.GAME_LIST] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createGameSearchTextField({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_SEARCH_TEXT_FIELD,
    preview: ({ draft }: any) => draft.title.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}
export function createGameStudio({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_STUDIO,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'logo',
              title: 'Logo',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'thumb',
              title: 'Thumbnail',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'bgImage',
              title: 'Background Image',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'mobileBgImage',
              title: 'Mobile Bg Image',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
            {
              name: 'page',
              title: 'Linked Page',
              type: reference({ contentTypes: [ContentTypes.PAGE] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}
export function createGameDescription({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_DESCRIPTION,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'slug',
              title: 'Slug',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
          ],
          locales
        ),
      },
    ],
  };
}
const CAMPAIGN_PERIOD = [
  { text: 'Daily', value: '1' },
  { text: 'Weekly', value: '7' },
];

export function createGameCampaign({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GAME_CAMPAIGN,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'startDate',
              title: 'Starts from',
              type: dateTime(),
            },
            /*TO BE REMOVED FROM*/
            {
              name: 'upcomingThumb',
              title: 'UpcomingThumbnail',
              type: text(),
            },
            {
              name: 'paidThumb',
              title: 'Paid Thumbnail',
              type: text(),
            },
            {
              name: 'games',
              title: 'Games',
              type: referenceMany({ contentTypes: [ContentTypes.GAME] }),
            },
            /*TO BE REMOVED TO*/
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'campaignGames',
                    fields: [
                      {
                        name: 'game',
                        title: 'Game',
                        type: reference({ contentTypes: [ContentTypes.GAME] }),
                      },
                      {
                        name: 'startDate',
                        title: 'Starts from',
                        type: dateTime(),
                      },
                      {
                        name: 'endDate',
                        title: 'Ends at',
                        type: dateTime(),
                      },
                      {
                        name: 'upcomingThumb',
                        title: 'UpcomingThumbnail',
                        type: text(),
                      },
                      {
                        name: 'paidThumb',
                        title: 'Paid Thumbnail',
                        type: text(),
                      },
                      {
                        name: 'bannerText',
                        title: 'Current text',
                        type: text(),
                      },
                      {
                        name: 'upcomingBannerText',
                        title: 'Upcoming text',
                        type: text(),
                      },
                      {
                        name: 'altBannerImageDesktop',
                        title: 'Alternative Banner Image Desktop',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'altBannerImageMobile',
                        title: 'Alternative Banner Image Mobile',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
      {
        name: 'showAllNext',
        title: 'Show Following Dates (With Overlay)',
        type: bool(),
      },
      {
        name: 'showPrevious',
        title: 'Show Previous ',
        type: bool(),
      },
      {
        name: 'renderType',
        title: 'Render Type ',
        type: select({
          types: [
            { text: 'Tile', value: 'tile' },
            { text: 'Banner', value: 'banner' },
          ],
        }),
      },
    ],
  };
}

export function createFooter({
  locales,
  additionalBlocks = [],
}: {
  additionalBlocks?: Block[];
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.FOOTER,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en),
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  ...additionalBlocks,
                  {
                    type: 'menuBlock',
                    fields: [
                      {
                        name: 'menu',
                        title: 'Menu',
                        type: reference({ contentTypes: [ContentTypes.MENU] }),
                      },
                    ],
                  },
                  {
                    type: 'paymentMethodsBlock',
                    fields: [
                      {
                        name: 'paymentMethods',
                        title: 'Payment Methods',
                        type: reference({ contentTypes: [ContentTypes.PAYMENT_METHODS] }),
                      },
                    ],
                  },
                  {
                    type: 'safetyAndCertificatesBlock',
                    fields: [],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCashbackOptIn({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.CASHBACK_OPT_IN,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCashier({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.CASHIER,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'amount1',
              title: 'Amount 1',
              type: text(),
            },
            {
              name: 'amount2',
              title: 'Amount 2',
              type: text(),
            },
            {
              name: 'amount3',
              title: 'Amount 3',
              type: text(),
            },
            {
              name: 'enablePaymentIQCashier',
              title: 'Enable PaymnetIQ Cashier (Default)',
              type: bool(),
            },
            {
              name: 'enableZimplerCashier',
              title: 'Enable Zimpler Cashier',
              type: bool(),
            },
            {
              name: 'sofortText',
              title: 'Sofort Text',
              type: text(),
            },
            {
              name: 'bonusText',
              title: 'Bonus Text',
              type: text(),
            },
            {
              name: 'sofortLogo',
              title: 'Sofort Logo',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
          ],
          locales
        ),
      },
      {
        name: 'isWithdrawal',
        title: 'Is withdrawal',
        type: bool(),
      },
      {
        name: 'identifier',
        title: 'Identifier',
        type: text(),
      },
    ],
  };
}

export function createAccordion({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.ACCORDION,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'accordionItem',
                    fields: [
                      {
                        name: 'title',
                        title: 'Title',
                        type: text(),
                      },
                      {
                        name: 'content',
                        title: 'Content',
                        type: text(),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createAccessCashier({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.ACCESS_CASHIER,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'customLabel',
              title: 'Custom Label',
              type: text(),
            },
            {
              name: 'amountLabel',
              title: 'Amount Label',
              type: text(),
            },
            {
              name: 'amountPlaceholder',
              title: 'Amount Placeholder',
              type: text(),
            },
            {
              name: 'buttonLabel',
              title: 'Button Label',
              type: text(),
            },
            {
              name: 'amount1',
              title: 'Amount 1',
              type: text(),
            },
            {
              name: 'amount2',
              title: 'Amount 2',
              type: text(),
            },
            {
              name: 'amount3',
              title: 'Amount 3',
              type: text(),
            },
            {
              name: 'loginLinkLabel',
              title: 'Login Link Label',
              type: text(),
            },
            {
              name: 'hasBadge',
              title: 'Has a badge on top',
              type: bool(),
            },
          ],
          locales
        ),
      },
      {
        name: 'isWithdrawal',
        title: 'Is withdrawal',
        type: bool(),
      },
      {
        name: 'identifier',
        title: 'Identifier',
        type: text(),
      },
    ],
  };
}

export function createUpdateEmailForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.UPDDATE_EMAIL_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'emailFieldLabel',
              title: 'Email Field Label',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdatePersonalDetailsForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.UPDDATE_PERSONAL_DETAILS_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'firstNameFieldLabel',
              title: 'First Name Field Label',
              type: text(),
            },
            {
              name: 'middleNameFieldLabel',
              title: 'Middle Name Field Label',
              type: text(),
            },
            {
              name: 'lastNameFieldLabel',
              title: 'Last Name Field Label',
              type: text(),
            },
            {
              name: 'dobFieldLabel',
              title: 'Date of Birth Field Label',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
            {
              name: 'ssnFieldLabel',
              title: 'SSN Field Label',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateAddressForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_ADDRESS_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'streetFieldLabel',
              title: 'Street Field Label',
              type: text(),
            },
            {
              name: 'cityFieldLabel',
              title: 'City Field Label',
              type: text(),
            },
            {
              name: 'countryFieldLabel',
              title: 'Country Field Label',
              type: text(),
            },
            {
              name: 'zipCodeFieldLabel',
              title: 'Zip Code Field Label',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateMarketingSettingsForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_MARKETING_SETTINGS_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'acceptsSmsMarketingFieldLabel',
              title: 'Accepts Sms Marketing Field Label',
              type: text(),
            },
            {
              name: 'acceptsEmailMarketingFieldLabel',
              title: 'Accepts Email Marketing Field Label',
              type: text(),
            },
            {
              name: 'acceptsPhoneMarketingFieldLabel',
              title: 'Accepts Phone Marketing Field Label',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createAdditionalInformationForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.ADDITIONAL_INFORMATION_FORM,
    preview: ({ draft }: any) => draft.title.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'shortZimplerTitle',
              title: 'Short Flow Title (Finland)',
              type: text(),
            },
            {
              name: 'zimplerTitle',
              title: 'Zimpler Flow Title (Sweden)',
              type: text(),
            },
            {
              name: 'shortZimplerSubtitle',
              title: 'Short Flow Sub Title (Finland)',
              type: text(),
            },
            {
              name: 'zimplerSubtitle',
              title: 'Zimpler Flow Sub Title (Sweden)',
              type: text(),
            },
            {
              name: 'dobFieldLabel',
              title: 'Date of Birth Field Label',
              type: text(),
            },
            {
              name: 'emailFieldLabel',
              title: 'Email Field Label',
              type: text(),
            },
            {
              name: 'firstNameFieldLabel',
              title: 'First Name Field Label',
              type: text(),
            },
            {
              name: 'middleNameFieldLabel',
              title: 'Middle Name Field Label',
              type: text(),
            },
            {
              name: 'lastNameFieldLabel',
              title: 'Last Name Field Label',
              type: text(),
            },
            {
              name: 'streetFieldLabel',
              title: 'Street Field Label',
              type: text(),
            },
            {
              name: 'cityFieldLabel',
              title: 'City Field Label',
              type: text(),
            },
            {
              name: 'countryFieldLabel',
              title: 'Country Field Label',
              type: text(),
            },
            {
              name: 'zipCodeFieldLabel',
              title: 'Zip Code Field Label',
              type: text(),
            },
            {
              name: 'marketingSmsFieldLabel',
              title: 'Marketing SMS Field Label',
              type: text(),
            },
            {
              name: 'offersAndMarketingFieldLabel',
              title: 'Offers and Marketing Field Label',
              type: text(),
            },
            {
              name: 'acceptAllLabel',
              title: 'Accept All Field Label',
              type: text(),
            },
            {
              name: 'marketingEmailFieldLabel',
              title: 'Marketing Email Field Label',
              type: text(),
            },
            {
              name: 'ssnFieldLabel',
              title: 'SSN Field Label',
              type: text(),
            },
            {
              name: 'ssnFieldPlaceholder',
              title: 'SSN Field Placeholder',
              type: text(),
            },
            {
              name: 'viewTermsLabel',
              title: 'Terms Link Field Label',
              type: text(),
            },
            {
              name: 'viewPrivacyLabel',
              title: 'Privacy Link Field Label',
              type: text(),
            },
            {
              name: 'termsFieldLabel',
              title: 'Terms Checkbox Field Label',
              type: text(),
            },
            {
              name: 'privacyFieldLabel',
              title: 'Privacy Checkbox Field Label',
              type: text(),
            },
            {
              name: 'dayDepositLimitLabel',
              title: 'Day Deposit Limit Field Label',
              type: text(),
            },
            {
              name: 'weekDepositLimitLabel',
              title: 'Week Deposit Limit Field Label',
              type: text(),
            },
            {
              name: 'monthDepositLimitLabel',
              title: 'Month Deposit Limit Field Label',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
            {
              name: 'submitShortButtonName',
              title: 'Short Flow Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
      {
        name: 'countries',
        title: 'Countries',
        type: referenceMany({ contentTypes: [ContentTypes.COUNTRY] }),
      },
      {
        name: 'showEniro',
        title: 'Show Eniro',
        type: bool(),
      },
      {
        name: 'showLimits',
        title: 'Show Limits',
        type: bool(),
      },
      {
        name: 'enableShortRegistration',
        title: 'Short Registration',
        type: bool(),
      },
    ],
  };
}

export function createKycValidationForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.KYC_VALIDATION,
    preview: ({ draft }: any) => draft.title.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'dobFieldLabel',
              title: 'Date of Birth Field Label',
              type: text(),
            },
            {
              name: 'emailFieldLabel',
              title: 'Email Field Label',
              type: text(),
            },
            {
              name: 'firstNameFieldLabel',
              title: 'First Name Field Label',
              type: text(),
            },
            {
              name: 'middleNameFieldLabel',
              title: 'Middle Name Field Label',
              type: text(),
            },
            {
              name: 'lastNameFieldLabel',
              title: 'Last Name Field Label',
              type: text(),
            },
            {
              name: 'streetFieldLabel',
              title: 'Street Field Label',
              type: text(),
            },
            {
              name: 'cityFieldLabel',
              title: 'City Field Label',
              type: text(),
            },
            {
              name: 'zipCodeFieldLabel',
              title: 'Postal Code Field Label',
              type: text(),
            },

            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createKycVerificationForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.KYC_VERIFICATION,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'verifyButtonName',
              title: 'Verify Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createTranslation({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.TRANSLATION,
    preview: ({ draft }: any) => draft?.key,
    title: ({ draft }: any) => draft?.key,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
          ],
          locales
        ),
      },
      {
        name: 'key',
        title: 'Key',
        type: text(),
      },
    ],
  };
}

export function createLockAccountForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.LOCK_ACCOUNT_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'customButtonName',
              title: 'Custom Button Name',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateDepositLimitForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_DEPOSIT_LIMIT_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateWagerLimitForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_WAGER_LIMIT_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createImage({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.IMAGE,
    preview: ({ draft }: any) => draft?.alt?.en,
    title: ({ draft }: any) => draft?.alt?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'alt',
              title: 'Alt',
              type: text(),
            },
            {
              name: 'image',
              title: 'Path',
              type: text(),
            },
          ],
          locales
        ),
      },
      {
        name: 'key',
        title: 'Key',
        type: text(),
      },
    ],
  };
}

export function createImageSlider({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.IMAGE_SLIDER,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'slideBlock',
                    fields: [
                      {
                        name: 'slide',
                        title: 'Slide',
                        type: reference({ contentTypes: [ContentTypes.SLIDE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createPromotion({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.PROMOTION,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'promotionLink',
              title: 'Promotion Link',
              type: reference({ contentTypes: [ContentTypes.PAGE] }),
            },
            {
              name: 'promotionLinkText',
              title: 'Promotion Link Text',
              type: text(),
            },
            {
              name: 'showRedirectButton',
              title: 'Show Redirect Button',
              type: bool(),
            },
            {
              name: 'image',
              title: 'Image',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'startDate',
              title: 'Start Date',
              type: dateTime(),
            },
            {
              name: 'endDate',
              title: 'End Date',
              type: dateTime(),
            },
            {
              name: 'actionButton',
              title: 'Action Button Text',
              type: text(),
            },
            {
              name: 'actionButtonLink',
              title: 'Action Button Link',
              type: text(),
            },
            {
              name: 'readMore',
              title: 'Read More Text',
              type: text(),
            },
            {
              name: 'readLess',
              title: 'Read Less Text',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'promotionImageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
            {
              name: 'termsBody',
              title: 'Terms Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createAffiliate({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.AFFILIATE,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'image',
              title: 'Image',
              type: reference({ contentTypes: [ContentTypes.IMAGE] }),
            },
            {
              name: 'actionButton',
              title: 'Action Button Text',
              type: text(),
            },
            {
              name: 'actionButtonLink',
              title: 'Action Button Link',
              type: text(),
            },
            {
              name: 'starsRating',
              title: 'Stars rating(Ex: 1 to 5)',
              type: text(),
            },
            {
              name: 'paymentIcon',
              title: 'Payment Icon',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
            {
              name: 'termsAndConditionsText',
              title: 'Terms and Conditions Text',
              type: text(),
            },
            {
              name: 'termsAndConditionsLink',
              title: 'Terms and Conditions Link',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createGuide({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.GUIDE,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'guideImage',
              title: 'Guide Image',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'guidePageLink',
              title: 'Guide Page Link',
              type: reference({ contentTypes: [ContentTypes.PAGE] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

const NEWS_TYPES = [
  { text: 'New', value: 'new' },
  { text: 'Tournament', value: 'tournament' },
  { text: 'Cashback', value: 'cashback' },
  { text: 'Games', value: 'games' },
  { text: 'Events/Promotions', value: 'promotions' },
];

export function createNews({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.NEWS,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'newsImage',
              title: 'News Image',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'readTime',
              title: 'Read Time (Ex: 1m)',
              type: text(),
            },
            {
              name: 'startDate',
              title: 'Starts from',
              type: dateTime(),
            },
            {
              name: 'endDate',
              title: 'End Date',
              type: dateTime(),
            },
            {
              name: 'type',
              title: 'Type',
              type: select({ types: NEWS_TYPES }),
            },
            {
              name: 'newsPageLink',
              title: 'News Page Link',
              type: reference({ contentTypes: [ContentTypes.PAGE] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createSimpleImageSlider({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.SIMPLE_IMAGE_SLIDER,
    preview: ({ draft }: any) => idx(draft, (_) => _.name.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'simpleSlideBlock',
                    fields: [
                      {
                        name: 'simpleSlide',
                        title: 'Simple Slide',
                        type: reference({ contentTypes: [ContentTypes.SIMPLE_SLIDE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createSlide({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.SLIDE,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'button',
              title: 'Button text',
              type: text(),
            },
            {
              name: 'buttonPath',
              title: 'Button Path',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'position',
                        title: 'Position',
                        type: select({
                          types: [
                            { text: 'Background', value: 0 },
                            { text: 'Left', value: 1 },
                            { text: 'Center', value: 2 },
                            { text: 'Right', value: 3 },
                          ],
                        }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createSimpleSlide({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.SIMPLE_SLIDE,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'button',
              title: 'Button text',
              type: text(),
            },
            {
              name: 'buttonPath',
              title: 'Button Path',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'position',
                        title: 'Position',
                        type: select({
                          types: [
                            { text: 'Background', value: 0 },
                            { text: 'Image', value: 1 },
                          ],
                        }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createPaymentMethods({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.PAYMENT_METHODS,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateLossLimitForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_LOSS_LIMIT_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}
export function createError404({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.ERROR_404,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'buttonText',
              title: 'Button text',
              type: text(),
            },
            {
              name: 'buttonPath',
              title: 'Button Path',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createUpdateSessionTimeLimitForm({
  locales,
}: {
  locales: string[];
}): ContentDescription {
  return {
    contentType: ContentTypes.UPDATE_SESSION_TIME_LIMIT_FORM,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'subtitle',
              title: 'Sub Title',
              type: text(),
            },
            {
              name: 'submitButtonName',
              title: 'Submit Button Name',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCardList({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.CARD_LIST,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'cards',
              title: 'Cards',
              type: referenceMany({ contentTypes: [ContentTypes.CARD] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createCard({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.CARD,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'image',
              title: 'Image',
              type: text(),
            },
            {
              name: 'type',
              title: 'Type',
              type: text(),
            },
            {
              name: 'link',
              title: 'Link',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'position',
                        title: 'Position',
                        type: select({
                          types: [{ text: 'Background', value: 0 }],
                        }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createTextSection({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.TEXT_SECTION,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'image',
              title: 'Image',
              type: text(),
            },
            {
              name: 'type',
              title: 'Type',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'position',
                        title: 'Position',
                        type: select({
                          types: [{ text: 'Background', value: 0 }],
                        }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createImageSection({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.IMAGE_SECTION,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Description',
              type: text(),
            },
            {
              name: 'type',
              title: 'Type',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                      {
                        name: 'position',
                        title: 'Position',
                        type: select({
                          types: [{ text: 'Background', value: 0 }],
                        }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createExternalLink({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.EXTERNAL_LINK,
    preview: ({ draft }: any) => idx(draft, (_) => _.title.en) || '',
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'url',
              title: 'URL',
              type: text(),
            },
            {
              name: 'rel',
              title: 'REL',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

const FUNCTIONS = [
  { text: 'Log Out', value: 'logout' },
  { text: 'Game Provider Drop Down', value: 'gameProviderDropDown' },
  { text: 'More Slot Themes Menu', value: 'slotThemesMenu' },
];

const MENU_TYPES = [
  { text: 'Main', value: 'mainMenu' },
  { text: 'Tabs', value: 'tabsMenu' },
  { text: 'Footer', value: 'footerMenu' },
];

export function createMenu({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.MENU,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'pageLinkBlock',
                    fields: [
                      {
                        name: 'page',
                        title: 'Page',
                        type: reference({ contentTypes: [ContentTypes.PAGE] }),
                      },
                    ],
                  },
                  {
                    type: 'externalLinkBlock',
                    fields: [
                      {
                        name: 'externalLink',
                        title: 'External Link',
                        type: reference({ contentTypes: [ContentTypes.EXTERNAL_LINK] }),
                      },
                    ],
                  },
                  {
                    type: 'functionBlock',
                    fields: [
                      {
                        name: 'function',
                        title: 'Function',
                        type: select({ types: FUNCTIONS }),
                      },
                    ],
                  },
                  {
                    type: 'pageLinkDropDownBlock',
                    fields: [
                      {
                        name: 'pageLinkDropDown',
                        title: 'Page Link Dropdown',
                        type: referenceMany({ contentTypes: [ContentTypes.PAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
      {
        name: 'menuType',
        title: 'MenuType',
        type: select({ types: MENU_TYPES }),
      },
      {
        name: 'identifier',
        title: 'Identifier',
        type: text(),
      },
    ],
  };
}

export function createConfig(): ContentDescription {
  return {
    contentType: ContentTypes.CONFIG,
    preview: ({ draft }: any) => idx(draft, (_) => _.name) || '',
    fields: [
      {
        name: 'name',
        title: 'Name',
        type: text(),
      },
      {
        name: 'body',
        title: 'Body',
        type: blockEditor({
          blocks: [
            {
              type: 'currencyBlock',
              fields: [
                {
                  name: 'currency',
                  title: 'Currency',
                  type: reference({ contentTypes: [ContentTypes.CURRENCY] }),
                },
              ],
            },
          ],
        }),
      },
      {
        name: 'license',
        title: 'License',
        type: reference({ contentTypes: [ContentTypes.LICENSE] }),
      },
    ],
  };
}

export function createCurrency(): ContentDescription {
  return {
    contentType: ContentTypes.CURRENCY,
    preview: ({ draft }: any) => idx(draft, (_) => _.name) || '',
    fields: [
      {
        name: 'name',
        title: 'Name',
        type: text(),
      },
      {
        name: 'value',
        title: 'Value',
        type: text(),
      },
      {
        name: 'accessCashierAmount',
        title: 'Access Cashier Amount 1',
        type: text(),
      },
      {
        name: 'accessCashierAmount2',
        title: 'Access Cashier Amount 2',
        type: text(),
      },
      {
        name: 'accessCashierAmount3',
        title: 'Access Cashier Amount 3',
        type: text(),
      },
      {
        name: 'symbol',
        title: 'Currency Symbol',
        type: text(),
      },
      {
        name: 'pnpMinDepositAmount',
        title: 'PnP Minimum Deposit Amount',
        type: text(),
      },
    ],
  };
}

export function createTermsAndConditions({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.TERMS_AND_CONDITIONS,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'datedAt',
              title: 'Dated at',
              type: dateTime(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createPrivacyPolicy({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.PRIVACY_POLICY,
    preview: ({ draft }: any) => draft?.title?.en,
    title: ({ draft }: any) => draft?.title?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Title',
              type: text(),
            },
            {
              name: 'datedAt',
              title: 'Dated at',
              type: dateTime(),
            },
            {
              name: 'body',
              title: 'Body',
              type: blockEditor(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createRepositoryGameCategory(): ContentDescription {
  return {
    contentType: ContentTypes.REPOSITORY_GAME_CATEGORY,
    preview: ({ draft }: any) => draft.name || '',
    fields: [
      {
        name: 'name',
        title: 'Name',
        type: text(),
      },
      {
        name: 'ref',
        title: 'Global Game Category Reference',
        type: reference({ contentTypes: [ContentTypes.GAME_CATEGORY], repositories: ['global'] }),
      },
      {
        name: 'page',
        title: 'Page',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
    ],
  };
}

export function createBonusProgram({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.BONUS_PROGRAM,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'numberOfFreeSpins',
              title: 'Number of free spins',
              type: number(),
            },
            {
              name: 'expiryDate',
              title: 'Expire at',
              type: dateTime(),
            },
            {
              name: 'gamesIds',
              title: 'Games IDs',
              type: referenceMany({ contentTypes: [ContentTypes.GAME] }),
            },
          ],
          locales
        ),
      },
    ],
  };
}

const INPUT_TYPES = [
  { text: 'Text', value: 'text' },
  { text: 'Checkbox', value: 'checkbox' },
  { text: 'Checkbox Other', value: 'checkboxOther' },
  { text: 'Radio', value: 'radio' },
  { text: 'Range', value: 'range' },
  { text: 'File', value: 'file' },
];

export function createForm({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.FORM,
    preview: ({ draft }: any) => draft?.name?.en,
    title: ({ draft }: any) => draft?.name?.en,
    fields: [
      {
        type: translatable(
          [
            {
              name: 'name',
              title: 'Name',
              type: text(),
            },
            {
              name: 'formBody',
              title: 'Form Body',
              type: blockEditor({
                blocks: [
                  {
                    type: 'imageBlock',
                    fields: [
                      {
                        name: 'image',
                        title: 'Image',
                        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
                      },
                    ],
                  },
                ],
              }),
            },
            {
              name: 'formFields',
              title: 'Fields',
              type: blockEditor({
                blocks: [
                  {
                    type: 'input',
                    fields: [
                      {
                        name: 'type',
                        title: 'Type',
                        type: select({ types: INPUT_TYPES }),
                      },
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'salary',
                    fields: [
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'grossIncome',
                    fields: [
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'spending',
                    fields: [
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'countries',
                    fields: [
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'other',
                    fields: [
                      {
                        name: 'name',
                        title: 'Name',
                        type: text(),
                      },
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                    ],
                  },
                  {
                    type: 'submitButton',
                    fields: [
                      {
                        name: 'label',
                        title: 'Label',
                        type: text(),
                      },
                      {
                        name: 'mutation',
                        title: 'Mutation',
                        type: text(),
                      },
                    ],
                  },
                ],
              }),
            },
          ],
          locales
        ),
      },
      {
        name: 'formId',
        title: 'Form ID',
        type: text(),
      },
      {
        name: 'isMandatory',
        title: 'Is Mandatory',
        type: bool(),
      },
    ],
  };
}

const BANNER_TYPES = [
  { text: 'Fullwidth', value: 'fullwidth' },
  { text: 'Standard', value: 'standard' },
  { text: 'Game', value: 'game' },
  { text: 'External', value: 'external' },
];

export function createBanner({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.BANNER,
    preview: ({ draft }: any) => idx(draft, (_) => _.name) || '',
    fields: [
      {
        name: 'name',
        title: 'Name',
        type: text(),
      },
      {
        name: 'type',
        title: 'Type',
        type: select({ types: BANNER_TYPES }),
      },
      {
        name: 'mobileImage',
        title: 'Mobile Image',
        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
      },
      {
        name: 'desktopImage',
        title: 'Desktop Image',
        type: reference({ contentTypes: [ContentTypes.IMAGE] }),
      },
      {
        name: 'pageLink',
        title: 'Page Link',
        type: reference({ contentTypes: [ContentTypes.PAGE] }),
      },
      {
        name: 'gameLink',
        title: 'Game Link',
        type: reference({ contentTypes: [ContentTypes.GAME] }),
      },
      {
        name: 'trackingEvent',
        title: 'Tracking Event',
        type: text(),
      },
      {
        type: translatable(
          [
            {
              name: 'bannerExternalLink',
              title: 'Banner External Link',
              type: text(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

export function createRegilyLastOffer({ locales }: { locales: string[] }): ContentDescription {
  return {
    contentType: ContentTypes.REGILY_LAST_OFFER,
    preview: ({ draft }: any) => idx(draft, (_) => _.title) || '',
    fields: [
      {
        name: 'title',
        title: 'Title',
        type: text(),
      },
      {
        type: translatable(
          [
            {
              name: 'title',
              title: 'Offer title',
              type: text(),
            },
            {
              name: 'description',
              title: 'Offer description',
              type: text(),
            },
            {
              name: 'enabled',
              title: 'Enabled offer',
              type: bool(),
            },
          ],
          locales
        ),
      },
    ],
  };
}

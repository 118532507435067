import global from './global';
import refuelcasino from './refuelcasino';
import mountgold from './mountgold';
import monkajicasino from './monkajicasino';
import amokcasino from './amokcasino';
import raptorcasino from './raptorcasino';
import goldroll from './goldroll';
import unlimitcasino from './unlimitcasino';
import gemler from './gemler';
import spinero from './spinero';
import winzie from './winzie';
import slotable from './slotable';
import luckyjungle from './luckyjungle';
import monsino from './monsino';
import f9casino from './f9casino';
import overloadcasino from './overloadcasino';
import djack from './djack';
import ref from './ref';
import affiliates from './affiliates';
import dinotech from './dinotech';
import infiniza from './infiniza';
import tekzia from './tekzia';
import mountgoldCom from './mountgoldCom';
import mountgoldEu from './mountgoldEu';
import mountgoldIo from './mountgoldIo';
import { Repository } from '../types';

const schema: Repository[] = [
  global,
  refuelcasino,
  mountgold,
  monkajicasino,
  amokcasino,
  raptorcasino,
  goldroll,
  unlimitcasino,
  gemler,
  spinero,
  winzie,
  slotable,
  luckyjungle,
  monsino,
  f9casino,
  overloadcasino,
  djack,
  ref,
  affiliates,
  dinotech,
  infiniza,
  tekzia,
  mountgoldCom,
  mountgoldEu,
  mountgoldIo,
];

export default schema;

import React from 'react';
import loadable from '@loadable/component';
import { Router } from '@reach/router';

import AppLayout from '@/containers/AppLayout';
import PrivateRoute from '@/containers/PrivateRoute';
import NewContentType from '@/screens/NewContentType';

const Dev = loadable(() => import('@/screens/Dev'));
const Login = loadable(() => import('@/screens/Login'));
const Player = loadable(() => import('@/screens/Player'));
const Version = loadable(() => import('@/screens/Version'));
const Placeholder = loadable(() => import('@/screens/Placeholder'));
const GameDeploy = loadable(() => import('@/screens/GameDeploy'));
const ContentView = loadable(() => import('@/screens/ContentView'));
const ReportView = loadable(() => import('@/screens/ReportView'));
const Numbers = loadable(() => import('@/screens/Numbers'));
const BrandSidebar = loadable(() => import('@/containers/BrandSidebar'));
const PlayerLimits = loadable(() => import('@/screens/PlayerLimits'));
const PlayerOverview = loadable(() => import('@/screens/PlayerOverview'));
const PlayerPayments = loadable(() => import('@/screens/PlayerPayments'));
const PlayerClosures = loadable(() => import('@/screens/PlayerClosures'));
const PlayerSessions = loadable(() => import('@/screens/PlayerSessions'));
const PlayerGameRounds = loadable(() => import('@/screens/PlayerGameRounds'));
const PlayerKYCHistory = loadable(() => import('@/screens/PlayerKYCHistory'));
const PlayerAnalytics = loadable(() => import('@/screens/PlayerAnalytics'));
const PlayerSOW = loadable(() => import('@/screens/PlayerSOW'));
const PlayerDocumentKYCHistory = loadable(() => import('@/screens/PlayerDocumentKYCHistory'));
const PlayerWithdrawal = loadable(() => import('@/screens/PlayerWithdrawal'));
const PlayerSearchForm = loadable(() => import('@/screens/PlayerSearchForm'));
const PlayerAdjustments = loadable(() => import('@/screens/PlayerAdjustments'));
const ContentTypeSidebar = loadable(() => import('@/containers/ContentTypeSidebar'));
const PlayerTransactions = loadable(() => import('@/screens/PlayerTransactions'));
const PlayerLimitsSession = loadable(() => import('@/screens/PlayerLimitsSession'));
const PlayerLimitsDeposit = loadable(() => import('@/screens/PlayerLimitsDeposit'));
const PlayerSearchResults = loadable(() => import('@/screens/PlayerSearchResults'));
const PlayerLinkedAccounts = loadable(() => import('@/screens/PlayerLinkedAccounts'));
const PlayerClosureTimeout = loadable(() => import('@/screens/PlayerClosureTimeout'));
const PlayerClosureSelfExcl = loadable(() => import('@/screens/PlayerClosureSelfExclusion'));
const PlayerClosureSelfExclHistory = loadable(() =>
  import('@/screens/PlayerClosureSelfExclusionHistory')
);
const PlayerLimitsDepositHistory = loadable(() => import('@/screens/PlayerLimitsDepositHistory'));
const PlayerLimitsSessionHistory = loadable(() => import('@/screens/PlayerLimitsSessionHistory'));
const PlayerHistory = loadable(() => import('@/screens/PlayerHistory'));
const PlayerPhoneLoginHistory = loadable(() => import('@/screens/PlayerPhoneLoginHistory'));
const Transactions = loadable(() => import('@/screens/Transactions'));
const KYCExpiration = loadable(() => import('@/screens/KYCExpiration'));
const CreateExcludedUser = loadable(() => import('@/screens/CreateExcludedUser'));
const LinkedAccounts = loadable(() => import('@/screens/LinkedAccounts'));
const PaymentsAndFraud = loadable(() => import('@/screens/PaymentsAndFraud'));
const BulkAdjustments = loadable(() => import('@/screens/BulkAdjustments'));
const SourceOfWealth = loadable(() => import('@/screens/SourceOfWealth'));

const IndexPage = () => (
  <Router primary={false}>
    <Login path="/login" />
    <Version path="/version" />
    <PrivateRoute path="/dev" component={Dev} />
    <PrivateRoute path="/" component={AppLayout}>
      <PrivateRoute path="/" component={PlayerSearchForm} />
      <PrivateRoute path="/game-deploy" component={GameDeploy} />
      <PrivateRoute path="/players" component={PlayerSearchResults} />
      <PrivateRoute path="/players/search" component={PlayerSearchForm} />
      <PrivateRoute path="/players/:playerId" component={Player}>
        <PrivateRoute path="/gaming" component={Placeholder} />
        <PrivateRoute path="/payments" component={PlayerPayments} />
        <PrivateRoute path="/overview" component={PlayerOverview} />
        <PrivateRoute path="/game-rounds" component={PlayerGameRounds} />
        <PrivateRoute path="/marketing" component={Placeholder} />
        <PrivateRoute path="/withdrawal" component={PlayerWithdrawal} />
        <PrivateRoute path="/adjustments" component={PlayerAdjustments} />
        <PrivateRoute path="/transactions" component={PlayerTransactions} />
        <PrivateRoute path="/sessions" component={PlayerSessions} />
        <PrivateRoute path="/linked-accounts" component={PlayerLinkedAccounts} />
        <PrivateRoute path="/closures" component={PlayerClosures}>
          <PrivateRoute path="/time-out" component={PlayerClosureTimeout} />
          <PrivateRoute path="/self-exclusion" component={PlayerClosureSelfExcl} />
          <PrivateRoute path="/self-exclusion-history" component={PlayerClosureSelfExclHistory} />
        </PrivateRoute>
        <PrivateRoute path="/documents" component={PlayerDocumentKYCHistory} />
        <PrivateRoute path="/history" component={PlayerHistory} />
        <PrivateRoute path="/phone-login-history" component={PlayerPhoneLoginHistory} />
        <PrivateRoute path="/kyc-history" component={PlayerKYCHistory} />
        <PrivateRoute path="/analytics" component={PlayerAnalytics} />
        <PrivateRoute path="/sow" component={PlayerSOW} />
        <PrivateRoute path="/limits" component={PlayerLimits}>
          <PrivateRoute path="/deposit" component={PlayerLimitsDeposit} />
          <PrivateRoute path="/session" component={PlayerLimitsSession} />
          <PrivateRoute path="/deposit/history" component={PlayerLimitsDepositHistory} />
          <PrivateRoute path="/session/history" component={PlayerLimitsSessionHistory} />
        </PrivateRoute>
      </PrivateRoute>
      <PrivateRoute path="/analytics" component={Numbers} />
      <PrivateRoute path="/cms/content/:repository" component={BrandSidebar}>
        <PrivateRoute path="/new-content-type" component={NewContentType} />
        <PrivateRoute path="/:type" component={ContentTypeSidebar}>
          <PrivateRoute path="/:contentId" component={ContentView} />
        </PrivateRoute>
      </PrivateRoute>
      <PrivateRoute path="admin" component={Placeholder} />
      <PrivateRoute path="marketing" component={Placeholder} />
      <PrivateRoute path="payments-fraud" component={PaymentsAndFraud}>
        <PrivateRoute path="/transactions" component={Transactions} />
        <PrivateRoute path="/kyc-validations" component={KYCExpiration} />
        <PrivateRoute path="/create-excluded-user" component={CreateExcludedUser} />
        <PrivateRoute path="/linked-accounts" component={LinkedAccounts} />
        <PrivateRoute path="/bulk-adjustments" component={BulkAdjustments} />
        <PrivateRoute path="/source-of-wealth" component={SourceOfWealth} />
      </PrivateRoute>
      <PrivateRoute path="product-management" component={Placeholder} />
    </PrivateRoute>
  </Router>
);

export default IndexPage;

import React, { FC, useEffect, useReducer, createContext } from 'react';
import Debug from 'debug';
import Cookie from 'js-cookie';

import logout from '@/helpers/logout';
import { getOkta } from '@/helpers/okta';

export enum SessionStatus {
  LOADING,
  LOGGED_IN,
  LOGGED_OUT,
}

export interface UserType {
  login: string;
}

export interface SessionState {
  user: UserType | undefined;
  status: SessionStatus | undefined;
  logout: Function;
  getToken: Function;
  initialize: Function;
  checkLoggedIn: Function;
  setOktaTokens: Function;
  handleOktaLogInFailure: Function;
}

export type ActionType =
  | { type: 'LOGGED_IN'; user: UserType }
  | { type: 'LOGGED_OUT' }
  | { type: 'LOADING' };

const debug = Debug('dino:session');

const initialState: SessionState = {
  user: undefined,
  status: undefined,
} as SessionState;

const SessionContext = createContext(initialState);

const reducer = (state: SessionState, action: ActionType): SessionState => {
  switch (action.type) {
    case 'LOGGED_IN':
      return { ...state, status: SessionStatus.LOGGED_IN, user: action.user };
    case 'LOGGED_OUT':
      return { ...state, status: SessionStatus.LOGGED_OUT };
    case 'LOADING':
      return { ...state, status: SessionStatus.LOADING };
    default:
      return state;
  }
};

const getToken = async () => {
  const access = await getOkta().authClient.tokenManager.get('access');

  if (access) {
    return access.accessToken;
  }

  return null;
};

const setOktaTokens = (res: any) => {
  const [access, id] = res;
  const okta = getOkta();

  okta.authClient.tokenManager.add('id', id);
  okta.authClient.tokenManager.add('access', access);
};

const checkLoggedIn = async (dispatch: Function) => {
  debug('[checkLoggedIn] get okta');
  const okta = getOkta();

  dispatch({ type: 'LOADING' });

  if (okta.hasTokensInUrl()) {
    debug('[checkLoggedIn] parse token from url');
    const res = await okta.authClient.token.parseFromUrl();
    setOktaTokens(res);
  }

  debug('[checkLoggedIn] getting token from storage');

  let token;
  try {
    token = await getToken();
  } catch (e) {
    debug('[checkLoggedIn] getting token error', e);
  }

  if (!token) {
    debug('[checkLoggedIn] no token in storage, logging out');
    dispatch({ type: 'LOGGED_OUT' });
    return;
  }

  const onSuccess = (res: any) => {
    debug('[checkLoggedIn] got session (id: %s)', res.id);
    if (res.status === 'ACTIVE') {
      dispatch({ type: 'LOGGED_IN', user: res });
    } else {
      dispatch({ type: 'LOGGED_OUT' });
    }
  };

  const onError = (err: any) => {
    console.error('Error getting session', err);
    dispatch({ type: 'LOGGED_OUT' });
  };

  debug('[checkLoggedIn] get session');
  okta.authClient.session.get().then(onSuccess).catch(onError);
};

let timeout: NodeJS.Timeout | undefined = undefined;

const reseActivity = (dispatch: Function) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    debug('[reseActivity] automatic activity logout');
    dispatch({ type: 'LOGGED_OUT' });
    logout();
  }, 3600 * 1000);
};

const SessionContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // reseActivity(dispatch);

  const value = {
    ...state,
    logout,
    getToken,
    setOktaTokens,
  };

  useEffect(() => {
    checkLoggedIn(dispatch);
  }, []);

  return (
    <SessionContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };

export default SessionContext;

import React from 'react';

import Link from './Link';
import Entity from './Entity';

export default function Element(props) {
  const { attributes, children, element } = props;

  let inlineStyle = { style: {} };

  if (element.children[0].alignCenter) {
    inlineStyle.style = { textAlign: 'center' };
  }

  const newAttributes = { ...attributes, ...inlineStyle };

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...newAttributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...newAttributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...newAttributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...newAttributes}>{children}</h4>;
    case 'list-item':
      return <li {...newAttributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...newAttributes}>{children}</ol>;
    case 'paragraph': {
      return <p {...newAttributes}>{children}</p>;
    }
    case 'entity':
      return <Entity {...props} />;
    case 'link':
      return <Link {...props}>{children}</Link>;
    default:
      return <div {...attributes}>{children}</div>;
  }
}

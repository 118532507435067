import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import noop from 'lodash-es/noop';
import LinkIcon from '@material-ui/icons/Link';
import MUIToolbar from '@material-ui/core/Toolbar';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import {
  MdFormatBold,
  MdFormatQuote,
  MdFormatItalic,
  MdFormatUnderlined,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatAlignCenter,
} from 'react-icons/md';

import ToolbarIconButton from './ToolbarIconButton';
import InsertBlockSelect from './InsertBlockSelect';

const Toolbar = (props) => {
  const {
    blocks = [],
    onTriggerControl = noop,
    getIsControlActive = noop,
    getIsControlDisabled = noop,
  } = props;

  const onContentTypeSelectChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      event.preventDefault();
      onTriggerControl(event, { value: 'entity', options: { type: event.target.value } });
    },
    [onTriggerControl]
  );

  const onControlChange = useCallback(
    (event, value) => {
      event.preventDefault();
      onTriggerControl(event, { value });
    },
    [onTriggerControl]
  );

  return (
    <MUIToolbar variant="dense" disableGutters style={{ minHeight: 42 }}>
      <ToolbarIconButton
        value="bold"
        active={getIsControlActive('bold')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('bold')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatBold size={17} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="italic"
        active={getIsControlActive('italic')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('italic')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatItalic size={16} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="underlined"
        active={getIsControlActive('underlined')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('underlined')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatUnderlined size={16} />
      </ToolbarIconButton>

      <ToolbarIconButton
        value="heading-one"
        active={getIsControlActive('heading-one')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('heading-one')}
        style={{ padding: 8, fontSize: 16 }}
      >
        H1
      </ToolbarIconButton>
      <ToolbarIconButton
        value="heading-two"
        active={getIsControlActive('heading-two')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('heading-two')}
        style={{ padding: 8, fontSize: 16 }}
      >
        H2
      </ToolbarIconButton>
      <ToolbarIconButton
        value="heading-three"
        active={getIsControlActive('heading-three')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('heading-three')}
        style={{ padding: 8, fontSize: 16 }}
      >
        H3
      </ToolbarIconButton>
      <ToolbarIconButton
        value="heading-four"
        active={getIsControlActive('heading-four')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('heading-four')}
        style={{ padding: 8, fontSize: 16 }}
      >
        H4
      </ToolbarIconButton>
      <ToolbarIconButton
        value="block-quote"
        active={getIsControlActive('block-quote')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('block-quote')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatQuote size={16} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="numbered-list"
        active={getIsControlActive('numbered-list')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('numbered-list')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatListNumbered size={16} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="bulleted-list"
        active={getIsControlActive('bulleted-list')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('bulleted-list')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatListBulleted size={16} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="alignCenter"
        active={getIsControlActive('alignCenter')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('alignCenter')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <MdFormatAlignCenter size={16} />
      </ToolbarIconButton>
      <ToolbarIconButton
        value="link"
        active={getIsControlActive('link')}
        onChange={onControlChange}
        disabled={getIsControlDisabled('link')}
        style={{ padding: 8, fontSize: 16 }}
      >
        <LinkIcon style={{ height: 16, width: 16 }} />
      </ToolbarIconButton>
      {!!blocks.length && (
        <InsertBlockSelect options={blocks} onChange={onContentTypeSelectChange} />
      )}
      <Box flexGrow={1} />
      <ToolbarIconButton
        value="fullscreen"
        onChange={onControlChange}
        style={{ padding: 8, fontSize: 16 }}
      >
        {!getIsControlActive('fullscreen') && <FullscreenIcon style={{ height: 16, width: 16 }} />}
        {getIsControlActive('fullscreen') && (
          <FullscreenExitIcon style={{ height: 16, width: 16 }} />
        )}
      </ToolbarIconButton>
    </MUIToolbar>
  );
};

export default Toolbar;

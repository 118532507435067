import React from 'react';
import get from 'lodash-es/get';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'react-final-form';

import RichTextEditor from '@/components/RichTextEditor';
import { FieldComponent } from '../ContentEditor';

const isOldFormat = (value) => {
  return get(value, 'blocks') || get(value, '0.object');
};

const RichTextEditorField: FieldComponent = (props) => {
  const { name, title, ...other } = props;

  return (
    <Field name={name}>
      {({ input }) => {
        const value = isOldFormat(input.value) ? undefined : input.value;
        const cleanVal = value
          ? value.map((item) => {
              if (item.type === 'paragraph' && !item.children.length) {
                return { type: 'paragraph', children: [{ text: '' }] };
              }
              return item;
            })
          : undefined;
        return (
          <FormControl fullWidth>
            <InputLabel>{title}</InputLabel>
            <RichTextEditor {...other} value={cleanVal} onChange={input.onChange} />
          </FormControl>
        );
      }}
    </Field>
  );
};

export default RichTextEditorField;

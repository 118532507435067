import { AnyObject } from '@/types/helpers';

export const TEXT_FORMATS = ['bold', 'italic', 'underlined', 'code', 'alignCenter'];
export const LIST_FORMATS = ['numbered-list', 'bulleted-list'];

export const BLOCK_FORMATS = [
  ...LIST_FORMATS,
  'heading-one',
  'heading-two',
  'heading-three',
  'heading-four',
  'block-quote',
  'entity',
];

export const CONTROLS: AnyObject = {
  bold: { type: 'format' },
  italic: { type: 'format' },
  underlined: { type: 'format' },
  alignCenter: { type: 'format' },
  'heading-one': { type: 'format' },
  'heading-two': { type: 'format' },
  'heading-three': { type: 'format' },
  'heading-four': { type: 'format' },
  'block-quote': { type: 'format' },
  'numbered-list': { type: 'format' },
  link: { type: 'format' },
  entity: { type: 'entity' },
  'bulleted-list': { type: 'format' },
  fullscreen: { type: 'fullscreen' },
};
